import React from 'react'

function Mission({missionData}) {
    return (
        <div>
            <div className="aboutMission"> 
                    <div className="aboutUsLeftSection containerwrap" >
                        <div className="aboutMissionTitle">{missionData.title}</div>
                        <div className="aboutUsHeading" style={{marginTop:0, height:"48px", color:"white"}}>{missionData.heading}</div>
                        <div className="aboutUsDescription" style={{marginTop: "36px", color:"white"}}>{missionData.description1}</div>
                        <div className="aboutUsDescription"  style={{marginTop: "36px", color:"white"}}>{missionData.description2}</div>
                    </div>
                </div>
        </div>
    )
}

export default Mission
