import React from 'react'
import img1 from '../../../images/img1.png'
import img2 from '../../../images/img2.png'
import aws from '../../../images/aws-logo.svg'
import microsoft from '../../../images/micro.svg'
import google from '../../../images/google.svg'
function AboutUs({ aboutData }) {
    return (
        <div className="aboutUs">
            <div className="containerwrap-about row">
                <div className="aboutUsLeftSection col-md-5 col-12">
                    <div className="aboutUsTitle">{aboutData.title}</div>
                    <p className="aboutUsHeading" style={{ color: "white" }}>{aboutData.heading}</p>
                    <div className="aboutUsDescription" style={{ color: "white" }}>{aboutData.description}</div>
                    <div className="aboutUslogoSection">
                        <div className="aboutUslogoSectionTitle">
                            <div className="title">
                                {aboutData.specialization}
                            </div>
                            <div className="brands">
                                <figure>



                                    <img className="pt-2" width="50" src={aws} alt="Amazon Web Services" />
                                </figure>
                                <figure>
                                    <img width="200" src={microsoft} alt="Azure" />
                                </figure>
                                <figure>
                                    <img width="192" src={google} srcset={`${google} 2x , ${google} 3x `} alt="Google Cloud" loading="lazy" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="aboutUsRightSection col-md-6 col-12">
                    <div className="row margin-none">
                        <div className="col-md-6 col-6 padding-none">
                            <img src={img1} alt="aws" className="right_padding" />
                        </div>
                        <div className="col-md-6 col-6 padding-none">
                            <img src={img2} alt="aws" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs
