import React from 'react'
import { navigate } from "gatsby";


function Heading({heroSection}) {
    return (
        <div className="upper_class">
        
        <div className="upper_class">
              <div className="aboutSection1">
                <div className="containerwrap">
                    <div className="aboutSection1Title">{heroSection.title}</div>
                    <h1 className="aboutSection1Heading" style={{  marginBottom: "0px" }}>{heroSection.heading1}<span>{heroSection.heading2}</span></h1>
                    {/* <div className="aboutSection1Heading">with the help of Cloud</div> */}
                </div>
            </div>
        </div>
        </div>
    )
}

export default Heading
