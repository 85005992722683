import React from "react";

import "../../../styles/about.scss";
import noImg from "../../../images/noImage.png";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
function CardAbout({ teamData }) {
  const data = useStaticQuery(graphql`
    query {
      allStrapiOpsioTeams {
        edges {
          node {
            name
            number
            email
            profile
            image {
              localFile {
                childImageSharp {
                  fixed {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `).allStrapiOpsioTeams.edges;
  return (
    <div className="team_page" style={{ backgroundColor: "#17263F" }}>
      <div className="containerwrap">
        <div className="platformHeroSectionTitle">{teamData.title}</div>
        <div className="platformHeroSectionHeading">{teamData.heading}</div>
        <div className="platformHeroSectiondes">{teamData.des}</div>
        <div className="aboutFilterOptions">
          {[
            data[0],
            ...data.slice(1).sort(() => (Math.random() > 0.5 ? 1 : -1)),
          ].map((val, index) => {
            return val.node.image.length > 0 &&
              val.node.name !== "Sujatha Kamal" ? (
              <div className="aboutFilterOption col-md-3 col-6" key={index}>
                <figure>
                  {val.node.image.length > 0 ? (
                    <Img
                      fixed={val.node.image[0].localFile.childImageSharp.fixed}
                      alt={val.node.name}
                      className="aboutFilterOptionImg"
                    />
                  ) : (
                    <img
                      src={noImg}
                      alt="about"
                      className="aboutFilterOptionImg"
                    />
                  )}
                </figure>
                <div className="aboutFilterMobileMargin">
                  <div className="aboutFilterOptionName">{val.node.name}</div>
                  <div className="aboutFilterOptionpostion">
                    {val.node.profile}
                  </div>
                  <div className="aboutFilterOptionDes">
                    <div>{val.node.email}</div>
                    <div>{val.node.number}</div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CardAbout;
