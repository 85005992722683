import React from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import MediaQuery from "react-responsive";


function SuccessStories({ storyData }) {

    return (
        <div className="aboutSuccessStories">
            <div className="containerwrap">
                <div className="aboutSuccesssStoryTitle">{storyData.title}</div>
                <div className="aboutSuccessStoryHeading">{storyData.description}</div>
                {/* <div className="aboutSuccesssStoryText">{storyData.description}</div> */}
                <div className="abtSucStCards">
                    <MediaQuery minWidth={1025}>
                        <Slider>
                            <div className="inside_about_card">
                                <div className="abtSucStCard cardList22">
                                    <div className="yeaer">{storyData.story[0].timeline}</div>
                                    <div className="row">
                                        <div className="abtSucStCardLeftSec card1 col-md-5 col-12">
                                            <div className="abtSucStCardLeftSecHeading">{storyData.story[0].cardDes}</div>
                                        </div>
                                        <div className="sldier_cotnent col-md-6 col-12">
                                            {storyData.story[0].list.map((val, index) => <div className="abtSucStCardBodyText" key={index}>
                                                {val}
                                            </div>)}

                                        </div>
                                    </div>
                                </div>
                               
                            </div>

                            <div className="inside_about_card">
                            <div className="abtSucStCard cardList">
                                    <div className="yeaer">{storyData.story[1].timeline}</div>
                                    <div className="row">
                                        <div className="abtSucStCardLeftSec card2 col-md-5 col-12">
                                            <div className="abtSucStCardLeftSecHeading"> {storyData.story[1].cardDes}</div>
                                        </div>

                                        <div className="sldier_cotnent col-md-6 col-12">
                                            {storyData.story[1].list.map((val, index) => <div className="abtSucStCardBodyText" key={index}>
                                                {val}
                                            </div>)}

                                        </div>
                                    </div>
                                </div>
                                <div className="abtSucStCard cardList">
                                    <div className="yeaer">{storyData.story[2].timeline}</div>
                                    <div className="row">
                                        <div className="abtSucStCardLeftSec card3 col-md-5 col-12">
                                            <div className="abtSucStCardLeftSecHeading"> {storyData.story[2].cardDes} </div>
                                        </div>
                                        <div className="sldier_cotnent col-md-6 col-12">
                                            {storyData.story[2].list.map((val, index) => <div className="abtSucStCardBodyText" key={index}>
                                                {val}
                                            </div>)}

                                        </div>
                                    </div>
                                </div>
                              
                            </div>

                            <div className="inside_about_card">
                            <div className="abtSucStCard cardList">
                                    <div className="yeaer">{storyData.story[3].timeline}</div>
                                    <div className="row">
                                        <div className="abtSucStCardLeftSec card4 col-md-5 col-12">
                                            <div className="abtSucStCardLeftSecHeading"> {storyData.story[3].cardDes}</div>
                                        </div>

                                        <div className="sldier_cotnent col-md-6 col-12">
                                            {storyData.story[3].list.map((val, index) => <div className="abtSucStCardBodyText" key={index}>
                                                {val}
                                            </div>)}

                                        </div>
                                    </div>
                                </div>
                                <div className="abtSucStCard cardList">
                                    <div className="yeaer">{storyData.story[4].timeline}</div>
                                    <div className="row">
                                        <div className="abtSucStCardLeftSec card5 col-md-5 col-12">
                                            <div className="abtSucStCardLeftSecHeading"> {storyData.story[2].cardDes} </div>
                                        </div>
                                        <div className="sldier_cotnent col-md-6 col-12">
                                            {storyData.story[2].list.map((val, index) => <div className="abtSucStCardBodyText" key={index}>
                                                {val}
                                            </div>)}

                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                        </Slider>
                    </MediaQuery>
                    <MediaQuery maxWidth={1024}>
                        <Slider>
                            <div className="inside_about_card">
                                <div className="abtSucStCard cardList">
                                    <div className="yeaer">{storyData.story[0].timeline}</div>
                                    <div className="row">
                                        <div className="abtSucStCardLeftSec card1 col-md-5 col-12">
                                            <div className="abtSucStCardLeftSecHeading">{storyData.story[0].cardDes}</div>
                                        </div>
                                        <div className="sldier_cotnent col-md-6 col-12">
                                            {storyData.story[0].list.map((val, index) => <div className="abtSucStCardBodyText" key={index}>
                                                {val}
                                            </div>)}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="inside_about_card">
                                <div className="abtSucStCard cardList">
                                    <div className="yeaer">{storyData.story[1].timeline}</div>
                                    <div className="row">
                                        <div className="abtSucStCardLeftSec card2 col-md-5 col-12">
                                            <div className="abtSucStCardLeftSecHeading"> {storyData.story[1].cardDes}</div>
                                        </div>

                                        <div className="sldier_cotnent col-md-6 col-12">
                                            {storyData.story[1].list.map((val, index) => <div className="abtSucStCardBodyText" key={index}>
                                                {val}
                                            </div>)}

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="inside_about_card">

                                <div className="abtSucStCard cardList">
                                    <div className="yeaer">{storyData.story[2].timeline}</div>
                                    <div className="row">
                                        <div className="abtSucStCardLeftSec card3 col-md-5 col-12">
                                            <div className="abtSucStCardLeftSecHeading"> {storyData.story[2].cardDes} </div>
                                        </div>
                                        <div className="sldier_cotnent col-md-6 col-12">
                                            {storyData.story[2].list.map((val, index) => <div className="abtSucStCardBodyText" key={index}>
                                                {val}
                                            </div>)}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="inside_about_card">
                                <div className="abtSucStCard cardList">
                                    <div className="yeaer">{storyData.story[3].timeline}</div>
                                    <div className="row">
                                        <div className="abtSucStCardLeftSec card4 col-md-5 col-12">
                                            <div className="abtSucStCardLeftSecHeading"> {storyData.story[3].cardDes}</div>
                                        </div>

                                        <div className="sldier_cotnent col-md-6 col-12">
                                            {storyData.story[3].list.map((val, index) => <div className="abtSucStCardBodyText" key={index}>
                                                {val}
                                            </div>)}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="inside_about_card">
                                <div className="abtSucStCard cardList">
                                    <div className="yeaer">{storyData.story[3].timeline}</div>
                                    <div className="row">
                                        <div className="abtSucStCardLeftSec card4 col-md-5 col-12">
                                            <div className="abtSucStCardLeftSecHeading"> {storyData.story[4].cardDes}</div>
                                        </div>

                                        <div className="sldier_cotnent col-md-6 col-12">
                                            {storyData.story[4].list.map((val, index) => <div className="abtSucStCardBodyText" key={index}>
                                                {val}
                                            </div>)}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </MediaQuery>
                </div>
                {/* </OwlCarousel> */}
            </div>
        </div>
    )
}

export default SuccessStories
